<template>
  <div class="flex flex-row justify-end">
    <ButtonLink class="mr-2" @click="navigateTo('/sign-in')">
      Sign in
    </ButtonLink>
    <ButtonPrimary @click="navigateTo('/create-account')">
      Create account
    </ButtonPrimary>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
