<template>
  <div class="h-24 flex flex-row justify-between items-center">
    <Logo />
    <NavHomepage v-if="!user" />
    <NavApp v-if="user" />
  </div>
</template>

<script setup>
const user = useSupabaseUser();
</script>

<style lang="scss" scoped></style>
