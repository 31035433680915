<template>
  <button
    type="button"
    class="rounded-full px-4 py-2 text-sm font-medium transition duration-300 text-slate-600 hover:bg-slate-100"
  >
    <slot></slot>
  </button>
</template>

<script setup></script>

<style lang="scss" scoped></style>
