<template>
  <div class="flex flex-row justify-end">
    <ButtonPrimary @click="openModal">{{ $t("ui.createStory") }}</ButtonPrimary>

    <FormDropdownLinks :options="accountSettingsOptions" />
  </div>
</template>

<script setup>
import { useModalStore } from "~/stores/useModalStore";
const modalStore = useModalStore();

const openModal = () => {
  modalStore.isOpen = true;
};

const accountSettingsOptions = [
  {
    label: "Children",
    route: "/account-settings/children",
  },
  {
    label: "Account settings",
    route: "/account-settings",
  },
  {
    label: "Sign out",
    route: "/sign-out",
  },
];
</script>

<style lang="scss" scoped></style>
